<template>
    <div class="loader">
    <v-overlay v-if="fullscreen && isShow" :opacity="opacity">
        <v-progress-circular
            class="align-self-center ma-3"
            indeterminate
            :size="size"
            :width="width"
            :color="color"
        >
        </v-progress-circular>
        <div class="inner-text">{{text}}</div>
    </v-overlay>
    <v-progress-circular v-else-if="isShow"
        class="align-self-center ma-3"
        indeterminate
        :style="{flex: flex}"
        :size="size"
        :width="width"
        :color="color"
    />
    </div>
</template>

<script>
export default {
components: {},
props: {
    isShow: {
        default: false
    },
    fullscreen: {
        default: false
    },
    opacity: {
        default: 0.8
    },
    flex:{
        default:''
    },
    size:{
        default:24
    },
    width:{
        default:3
    },
    color:{
        default:`white accent-2`
    },
    text:{
        default:''
    },
},
data() {
    return {
    };
},
computed: {},
watch: {
    value() {
    }
},
created() {},
mounted() {},
destroyed() {},
methods: {},
};
</script>

<style lang="scss" scoped>
@import "../sass/app.scss";

.loader{
    z-index: 999;
    text-align: center;
}
.inner-text{
    padding-top: 50px;
}
</style>
